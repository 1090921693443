import React from 'react';
import { Header } from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { Layout } from 'components/layout/layout';
import { Seo } from 'components/seo';

import 'styles/default.scss';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Header title="ページは見つかりませんでした" />
    <Footer />
  </Layout>
);

export default NotFoundPage;
